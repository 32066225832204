import React, { Component } from "react"

/* Components */
import { InnerLayout } from '../../components'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")

/* Widget Sessions */
class Sessions extends Component {
    constructor() {
        super()
        this.state = {
            status: "loading",
            sessions: null,
        }
    }

    componentDidMount = () => {
        this.load()
    }


    load = (reload = false) => {

        /* Reload Page */
        if (reload) {
            this.setState({ status: 'loading' })
        }

    }


    render = () => {

        const { status } = this.state

        if (status !== "ok") {
            return <InnerLayout status={status} reload={() => this.load(true)} />
        }

        return (
            <div>
                <div>

                </div>
            </div>
        )
    }

}

export default Sessions