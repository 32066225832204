import React, { Component } from "react"

/* Components */
import { Avatar, InnerLayout, Pagination } from "../../components"

/* Helpers */
import { withRouter, utils } from "../../helpers"

/* REST API */
import { list } from "../../api/Bonus"

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Modal */
import Modal from 'react-modal'


/* Moment Settings */
moment.locale("ru")


/* Page Bonus */
class Bonus extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0,
            page: 1,

            search: "",

            filter: false,
            operatorID: 0,
            tableID: 0,
            start: null,
            end: null,

            visible: false,
            active: null
        }

        this._timer = null
    }

    /* Load On Mount */
    componentDidMount = () => {
        this.load()
    }

    /* Create Search Timing */
    componentWillUnmount = () => {
        if (this._timer) {
            clearTimeout(this._timer)
            this._timer = null
        }
    }

    /* Load action */
    load = (reload = false, page = 1) => {

        /* Set Page */
        this.setState({ page })

        /* Reload Page */
        if (reload) {
            this.setState({ status: "loading" })
        }

        /* Fields */
        const { search, operatorID, tableID, start, end } = this.state

        /* Send to REST API */
        list({ page, search, operatorID, tableID, start, end }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })
    }


    /* Draw Container */
    _container = () => {

        const { status, data, page, count } = this.state

        if (status !== "ok") {
            return <InnerLayout status={status} inner reload={() => this.load(true, 1)} />
        }

        return (
            <div className="bonus--content">

                <div className="bonus-content-head">
                    <div className="bonus-content-head-number">#</div>
                    <div className="bonus-content-head-text operator">Оператор</div>
                    <div className="bonus-content-head-text">Игра</div>
                    <div className="bonus-content-head-text">Игрок</div>
                    <div className="bonus-content-head-text">Ставка</div>
                    <div className="bonus-content-head-text">Выигрыш</div>
                    <div className="bonus-content-head-text date">Дата</div>
                    <div className="bonus-content-head-more"></div>
                </div>

                {data.map((item, index) =>
                    <div onClick={() => this.setState({ visible: true, active: item })} className="bonus-content-row" key={`${index}`}>
                        <div className="bonus-content-number">{(page - 1) * 10 + index + 1}</div>
                        <div className="bonus-content-text operator">{item.operator}</div>
                        <div className="bonus-content-text table">
                            {item.game}
                            <span>{item.table}</span>
                        </div>
                        <div className="bonus-content-text">
                            <div className="bonus-content-user">
                                <Avatar uri={`/images/player/${item.image}`} size={30} />
                                <div className="bonus-content-user-box">
                                    <div className="bonus-content-user-name">{item.firstName}</div>
                                    <div className="bonus-content-user-player">{item.player}</div>
                                </div>
                            </div>
                        </div>
                        <div className="bonus-content-text bonus">
                            {utils.convertor(item.dollarBonus, "$", 0)}
                            {String(item.currency).toLowerCase() !== "usd" && <span>{utils.convertor(item.bonus, item.symbol, item.isAfter)} * {parseInt(item.currencyRate) > 0 ? Number(item.currencyRate).toFixed(2) : Number(item.currencyRate).toFixed(6)}</span>}
                        </div>
                        <div className="bonus-content-text win">
                            {utils.checkValue(item.win, null) ? utils.convertor(item.win * parseFloat(item.currencyRate), "$", 0) : "-"}
                            <span className="blue">{utils.checkValue(item.win, null) && utils.convertor(item.win, item.symbol, item.isAfter)}</span>
                            <span>{utils.checkValue(item.combination, "")}</span>
                            <span>{utils.checkValue(item.multiplier, null) && `${item.multiplier} : 1`}</span>
                        </div>
                        <div className="bonus-content-text date">
                            {moment(item.createdAt).format("HH:mm")}
                            <span>{moment(item.createdAt).format("DD MMM YYYY")}</span>
                        </div>
                        <div className="bonus-content-more">
                            <div className="bonus-content-arrow">
                                <img src="/images/arrow.png" alt="Arrow" />
                            </div>
                        </div>
                    </div>
                )}

                {/* Pagination */}
                <Pagination size={10} page={page} total={count} onPageChange={page => this.load(true, page)} />

            </div >
        )
    }


    /* View */
    _view = () => {

        const { visible, active } = this.state

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => this.setState({ visible: false, active: null })}
                className="bonus--modal"
                overlayClassName="bonus--overlay"
                closeTimeoutMS={200}
            >

                <div className="bonus--modal-header">
                    <h2>Информация</h2>
                    <div onClick={() => this.setState({ visible: false, active: null })} className="bonus--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {active &&
                    <div className="bonus--modal-container">
                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/shape.png" alt="Shape" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Номер</div>
                                    <div className="bonus--modal-element-value small">{active.uid}</div>
                                </div>
                            </div>

                            <div className="bonus--modal-element">
                                <Avatar uri={`/images/player/${active.image}`} size={24} />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">{active.firstName}</div>
                                    <div className="bonus--modal-element-value">{active.player}</div>
                                </div>
                            </div>


                        </div>

                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/platform.png" alt="Platform" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Оператор</div>
                                    <div className="bonus--modal-element-value">{active.operator}</div>
                                </div>
                            </div>

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/spades.png" alt="Spades" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Номер игры #{active.gameNumber}</div>
                                    <div className="bonus--modal-element-value">{active.game}</div>
                                    <span>{active.table}</span>
                                </div>
                            </div>


                        </div>


                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/clock.png" alt="Clock" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Дата</div>
                                    <div className="bonus--modal-element-value">{moment(active.createdAt).format("HH:mm, DD MMM YYYY")}</div>
                                </div>
                            </div>

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/currency.png" alt="Currency" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Курс валют (к доллару США)</div>
                                    {(parseFloat(active.currencyRate) > 0 && String(active.currency).toLowerCase() !== "usd") ? <div className="bonus--modal-element-value">{Number(1 / parseFloat(active.currencyRate)).toFixed(2)} ({active.currency} - USD)</div> : "-"}
                                </div>
                            </div>
                        </div>

                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/poker-chip.png" alt="Poker Chip" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Ставка</div>
                                    <div className="bonus--modal-element-value">{utils.convertor(active.dollarBonus, "$", 0)}</div>
                                    {String(active.currency).toLowerCase() !== "usd" && <span>{utils.convertor(active.bonus, active.symbol, active.isAfter)}</span>}
                                </div>
                            </div>

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/dollar-coin.png" alt="Dollar" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Выигрыш</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.win, null) ? utils.convertor(active.win * parseFloat(active.currencyRate), "$", 0) : "-"}</div>
                                    {String(active.currency).toLowerCase() !== "usd" && <span>{utils.checkValue(active.win, null) && utils.convertor(active.win, active.symbol, active.isAfter)}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/poker-game.png" alt="Poker" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Комбинация</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.win, null) ? active.combination : "-"}</div>
                                </div>
                            </div>

                            <div className="bonus--modal-element">
                                <img src="/images/bonus/cross-mark.png" alt="Cross" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Умножитель</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.win, null) ? `${active.multiplier}:1` : "-"}</div>
                                </div>
                            </div>

                        </div>

                        <div className="bonus--modal-box">

                            <div className="bonus--modal-element big">
                                <img src="/images/bonus/jackpot.png" alt="Jackpot" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">JACKPOT ({parseFloat(active.jackpotRatio)})</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.jackpot, null) ? utils.convertor(active.jackpot, "$") : "-"}</div>
                                    <div className="bonus--modal-element-value green">+ {utils.convertor(parseFloat(active.dollarBonus) * parseFloat(active.jackpotRatio), "$", 0)}</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.jackpot, null) ? utils.convertor(parseFloat(active.jackpot) + parseFloat(active.dollarBonus) * parseFloat(active.jackpotRatio), "$") : "-"}</div>
                                </div>
                            </div>

                            <div className="bonus--modal-element big">
                                <img src="/images/bonus/safe-box.png" alt="Safe-box" />
                                <div className="bonus--modal-element-inner">
                                    <div className="bonus--modal-element-name">Касса ({1 - parseFloat(active.jackpotRatio)})</div>
                                    <div className="bonus--modal-element-value">{utils.checkValue(active.score, null) ? utils.convertor(active.score, "$") : "-"}</div>
                                    <div className="bonus--modal-element-value green">+ {utils.convertor(parseFloat(active.dollarBonus) * (1 - parseFloat(active.jackpotRatio)), "$", 0)}</div>
                                    {active.win ? <div className="bonus--modal-element-value red">- {utils.convertor(active.win * parseFloat(active.currencyRate), "$", 0)}</div> : null}
                                    <div className="bonus--modal-element-value">
                                        {utils.checkValue(active.score, null)
                                            ? utils.convertor(
                                                parseFloat(active.score)
                                                + parseFloat(active.dollarBonus) * (1 - parseFloat(active.jackpotRatio))
                                                - (active.win ? parseFloat(active.win) * parseFloat(active.currencyRate) : 0)
                                                , "$")
                                            : "-"}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                }

            </Modal>
        )
    }

    render = () => {

        const { search } = this.state

        return (
            <div className="bonus--page">

                {/* Draw Header */}
                <div className="bonus--page-header">

                    {/* Page Title */}
                    <h1>Бонус</h1>

                    {/* Page Filters */}
                    <div className="bonus--page-filters">

                        {/* Search */}
                        <div onSubmit={this.search} className="bonus--page-search">
                            <img src="/images/bonus/search.png" alt="Search" />
                            <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск по номеру игры или по коду игрока" />
                        </div>

                        <div className="bonus--page-filters-box">

                            {/* Filter */}
                            <div className="bonus--page-filter">
                                <img src="/images/bonus/filter.png" alt="Filter" />
                            </div>

                            {/* Excel */}
                            <div className="bonus--page-excel">
                                .XLSX
                            </div>

                            {/* Settings */}
                            <div className="bonus--page-settings">
                                <img src="/images/bonus/setting.png" alt="Settings" />
                                <div className="bonus--page-settings-text">
                                    <span>Настройки</span>
                                    JACKPOT
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/* Container */}
                <div className="bonus--container">
                    {this._container()}
                </div>

                {this._view()}

            </div>
        )
    }

}


export default withRouter(Bonus)
