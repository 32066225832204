import React, { Component } from "react"

/* Components */
import { Input } from '../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { login } from '../api/Authorization'

/* Cookie */
import Cookies from "js-cookie"



/* Authorization Widget */
class Authorization extends Component {

    constructor() {
        super()

        this.state = {
            email: "",
            password: "",
            waiting: false,
            error: ""
        }
    }


    /* Login Action */
    login = () => {

        const { email, password } = this.state
        const { setToken } = this.props

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        if (password === "") {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        this.setState({ waiting: true })

        this._password.error("")
        this._email.error("")

        /* SEND REQUEST to REST API */
        login({ email, password }).then(response => {
            if (response.status === 200) {
                setToken(response.data.accessToken)
                localStorage.setItem("user", JSON.stringify(response.data.user))
                localStorage.setItem("token", response.data.accessToken)
                Cookies.set("refresh", response.data.refreshToken, { expires: 1 })
            }
            else if (response.status === 400) {
                this.setState({ error: "Данные неверные!" })
            }
            else if (response.status === 404) {
                this._email.error("Пользователь не найден!")
            }
            else if (response.status === 401) {
                this._password.error("Неверный пароль!")
            }
            else if (response.status === 429) {
                this.setState({ error: "Множество попыток входа! Попробуйте позднее." })
            }
            else {
                this.setState({ error: "Что-то пошло не так! Попробуйте позднее." })
            }
        }).catch(() => {
            this.setState({ error: "Что-то пошло не так! Попробуйте позднее." })
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }


    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.login()} className="button">Войти</div>
    }


    /* Clear */
    clear = () => {
        this.setState({ error: "" })
    }


    render = () => {

        const { email, password, error } = this.state

        return (
            <div className="authorization">

                <div className="game-logo">
                    <span>
                        <img src="/images/logo.png" alt="Logo" />
                    </span>
                    <div className="game-logo-box">
                        <h1>Game Center</h1>
                    </div>
                </div>


                <div className="authorization-wrapper">

                    <h2>Панель управления</h2>

                    <Input ref={ref => this._email = ref} label="Email" value={email} clear={() => this.clear()} onChange={event => this.setState({ email: event.target.value })} />
                    <Input ref={ref => this._password = ref} label="Пароль" value={password} clear={() => this.clear()} onChange={event => this.setState({ password: event.target.value })} secure />

                    {error !== "" && <div className="error">{error}</div>}

                    {this._button()}

                    <p><span>По всем вопросам:</span> <a href="mailto:info@alicorn.lv">info@alicorn.lv</a></p>

                </div>

            </div>
        )
    }

}

export default Authorization