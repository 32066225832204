import React, { Component } from "react"

/* Components */
import { Layout, Input } from "../../components"

/* REST API */
import { create } from '../../api/Operator'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from "../../helpers"

/* Swal */
import Swal from 'sweetalert2'


/* Page Operator Create */
class OperatorCreate extends Component {

    constructor() {
        super()

        this.state = {
            status: "ok",
            name: "",
            slug: "",
            startpoint: "",
            balanceURL: "",
            debitURL: "",
            creditURL: "",
            webhookURL: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: 'loading' })
        }

    }

    /* Save action */
    save = () => {

        const { name, slug, startpoint, balanceURL, debitURL, creditURL, webhookURL } = this.state
        const { navigate } = this.props

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите свое имя")
            return
        }

        if (slug === "" || slug === null || slug === undefined) {
            this._slug.error("Введите slug")
            return
        }

        this.setState({ waiting: true })

        create({ name, slug, startpoint, balanceURL, debitURL, creditURL, webhookURL }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Оператор успешно добавлен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })


    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, name, slug, startpoint, balanceURL, debitURL, creditURL, webhookURL } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Создать оператора</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._slug = ref} label="Slug" value={slug} onChange={event => this.setState({ slug: event.target.value })} />
                            <Input ref={ref => this._startpoint = ref} label="Startpoint" value={startpoint} onChange={event => this.setState({ startpoint: event.target.value })} />
                            <Input ref={ref => this._balance = ref} label="Balance url" value={balanceURL} onChange={event => this.setState({ balanceURL: event.target.value })} />
                            <Input ref={ref => this._debit = ref} label="Debit url" value={debitURL} onChange={event => this.setState({ debitURL: event.target.value })} />
                            <Input ref={ref => this._credit = ref} label="Credit url" value={creditURL} onChange={event => this.setState({ creditURL: event.target.value })} />
                            <Input ref={ref => this._webhook = ref} label="Webhook url" value={webhookURL} onChange={event => this.setState({ webhookURL: event.target.value })} />
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(OperatorCreate)