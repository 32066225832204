import React, { Component } from "react"

/* Components */
import { Layout, Input } from "../../components"

/* REST API */
import { get, edit } from '../../api/Operator'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from "../../helpers"

/* Swal */
import Swal from 'sweetalert2'



/* Page Operator Edit */
class OperatorEdit extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            name: "",
            slug: "",
            token: "",
            startpoint: "",
            balanceURL: "",
            debitURL: "",
            creditURL: "",
            webhookURL: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: 'loading' })
            }

            get({ id }).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    this.setState({
                        status: "ok",
                        token: utils.checkValue(data.operator.token),
                        name: utils.checkValue(data.operator.name),
                        slug: utils.checkValue(data.operator.slug),
                        startpoint: utils.checkValue(data.operator.startpoint),
                        balanceURL: utils.checkValue(data.operator.balanceURL),
                        debitURL: utils.checkValue(data.operator.debitURL),
                        creditURL: utils.checkValue(data.operator.creditURL),
                        webhookURL: utils.checkValue(data.operator.webhookURL)
                    })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission" })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key" })
                }
                else {
                    this.setState({ status: "error" })
                }
            }).catch(() => {
                this.setState({ status: "network" })
            })

        }
        else {
            this.setState({ status: "error" })
        }

    }

    /* Save action */
    save = () => {

        const { name, slug, startpoint, balanceURL, debitURL, creditURL, webhookURL } = this.state
        const { navigate, params } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите свое имя")
            return
        }

        if (slug === "" || slug === null || slug === undefined) {
            this._slug.error("Введите Slug")
            return
        }

        if (startpoint === "" || startpoint === null || slug === startpoint) {
            this._startpoint.error("Введите Startpoint")
            return
        }

        if (balanceURL === "" || balanceURL === null || slug === balanceURL) {
            this._balance.error("Введите Balance URL")
            return
        }

        if (debitURL === "" || debitURL === null || slug === debitURL) {
            this._debit.error("Введите Debit URL")
            return
        }

        if (creditURL === "" || creditURL === null || slug === creditURL) {
            this._balance.error("Введите Credit URL")
            return
        }

        if (webhookURL === "" || webhookURL === null || slug === webhookURL) {
            this._webhook.error("Введите Webhook URL")
            return
        }

        this.setState({ waiting: true })

        edit({ id, name, slug, startpoint, balanceURL, debitURL, creditURL, webhookURL }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Оператор успешно изменен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })


    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, name, slug, token, startpoint, balanceURL, debitURL, creditURL, webhookURL } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Редактировать оператора</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._slug = ref} label="Slug" value={slug} onChange={event => this.setState({ slug: event.target.value })} />
                            <Input disabled label="Token" value={token} />
                            <Input ref={ref => this._startpoint = ref} label="Startpoint" value={startpoint} onChange={event => this.setState({ startpoint: event.target.value })} />
                            <Input ref={ref => this._balance = ref} label="Balance url" value={balanceURL} onChange={event => this.setState({ balanceURL: event.target.value })} />
                            <Input ref={ref => this._debit = ref} label="Debit url" value={debitURL} onChange={event => this.setState({ debitURL: event.target.value })} />
                            <Input ref={ref => this._credit = ref} label="Credit url" value={creditURL} onChange={event => this.setState({ creditURL: event.target.value })} />
                            <Input ref={ref => this._webhook = ref} label="Webhook url" value={webhookURL} onChange={event => this.setState({ webhookURL: event.target.value })} />
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(OperatorEdit)