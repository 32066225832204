import moment from 'moment'

/* Show notification */
export const notification = (text, icon = null, timer = 1500) => {

    return {
        html: `<p class="notification">${text}</p>`,
        icon,
        color: 'white',
        background: 'black',
        showConfirmButton: false,
        position: 'top-right',
        timer,
        width: 400,
        backdrop: false,
        customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
    }
}


/* Check Value */
export const checkValue = (value, defaultValue = "") => {

    if (value === null || value === undefined) {
        return defaultValue
    }

    return value
}


/* Get time */
export const getTime = time => {

    if (time) {

        const date = moment(time).format("YYYY-MM-DD")
        const today = moment(new Date()).format("YYYY-MM-DD")

        const yesterdayD = new Date()
        yesterdayD.setDate(yesterdayD.getDate() - 1)
        const yesterday = moment(yesterdayD).format("YYYY-MM-DD")

        let text = moment(date).format("Do MMM YYYY")

        if (moment(date).isSame(today, 'day')) {
            text = 'Сегодня'
        }

        if (moment(date).isSame(yesterday, 'day')) {
            text = 'Вчера'
        }

        return {
            time: moment(time).format("HH:mm"),
            date: text
        }
    }

    return { time: "-", date: "-" }

}


/* Get Data */
export const permissionText = value => {

    const number = parseInt(value)

    if (Number.isInteger(number)) {

        if (number === 1) {
            return "доступ"
        }

        if (number > 1 && number <= 4) {
            return "доступа"
        }

        return "доступов"
    }

    return ""
}


/* Generate password */
export const generatePassword = () => {

    const length = 8
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!+_~@$%&*()"
    let password = ""

    for (var i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n))
    }

    return password
}


/* PRICE CONVERTOR */
export const convertor = (price, symbol = "", isAfter = 0, space = "\u0020") => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return ""

    const number = Number.parseFloat(price).toFixed(2)

    let before = ""
    let after = ""

    if (parseInt(isAfter) === 1) {
        after = "\u0020" + symbol
    }

    if (parseInt(isAfter) === 0) {
        before = symbol + "\u0020"
    }

    return before + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, space) + after
}




/* GET START AND END DATE  */
export const getDate = () => {

    const startDate = new Date()
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setDate(startDate.getDate() - 30)


    const endDate = new Date()
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    return { startDate, endDate }
}


/* GET START AND END DATE  */
export const getToday = () => {

    const startDate = moment().format('YYYY-MM-DD 00:00:00')
    const endDate = moment().format('YYYY-MM-DD 23:59:59')

    return { startDate, endDate }
}



/* Get Avatar Of Player */
export const playerAva = (isTester = 0) => {

    if (parseInt(isTester) === 1) {
        return '/images/player/tester-ava.png'
    }

    const ava = ['/images/player/gamer.png', '/images/player/gamer2.png', '/images/player/gamer3.png', '/images/player/gamer4.png']

    const random = Math.floor(Math.random() * ava.length)

    return ava[random]
}



export const converTime = second => {

    /* Check the second for the void */
    if (second === null || second === undefined)
        return "00:00:00"

    let number = parseInt(second, 10)
    let hours = Math.floor(number / 3600)
    let minutes = Math.floor((number - (hours * 3600)) / 60)
    let seconds = number - (hours * 3600) - (minutes * 60)

    if (hours < 10) { hours = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }
    if (seconds < 10) { seconds = "0" + seconds }

    return hours + ':' + minutes + ':' + seconds
}